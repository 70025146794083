import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import { ImageDiv } from "../styles/ProjectStyles"
import { StaticImage } from "gatsby-plugin-image"

import ReactPlayer from "react-player"

const greta = () => {
  return (
    <Layout>
      <section style={{ position: "relative" }}></section>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          backgroundColor: "#1d242a",
          padding: "40px",
          paddingTop: "70px",
        }}
      >
        <ImageDiv img={"/pkwebite-detail.png"} />
        <div
          style={{
            backgroundColor: "black",
            width: "50%",
            height: "700px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              paddingTop: "50px",
              color: "white",
              fontFamily: "Inconsolata",
              fontSize: "25px",
              margin: "40px",
              textAlign: "center",
            }}
          >
            <h2>Philipp Kristian: </h2>
            For the brand Philipp Kristian I created the new website. The
            website works with Gatsby.js and is adapted to the latest standards.
            The site can be viewed{" "}
            <Link
              style={{ color: "#e91150" }}
              to="https://philippkristian.com/"
            >
              HERE
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default greta
